import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import landing from '@/components/Landing.vue'
import Login from "@/views/Login.vue";
import NotFound from "@/views/NotFound.vue";
import NoCompanies from "@/views/NoCompanies.vue";
import viewCollection from "@/components/ViewCollection.vue";
import addCollection from "@/components/AddCollection.vue";
// import { store } from '@/store/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: { name: "Login"}
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Dashboard.vue"),
    children: [
      {
        path: '',
        component: landing,
        name: "dash.landing"
      },
      {
        path: '/collection/:id',
        name: 'dash.collection.view',
        component: viewCollection,
        props: true,
      },
      {
        path: '/add-collection',
        name: 'dash.collection.add',
        component: addCollection
      }
    ]
  },
  {
    path: "/Not-Authorized",
    name: "NoCompanies",
    component: NoCompanies
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound
  }
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});


export default router;

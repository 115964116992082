import {
  PublicClientApplication,
  SilentRequest,
  AuthenticationResult,
  AccountInfo,
  InteractionRequiredAuthError,
  RedirectRequest,
  PopupRequest,
  EndSessionRequest,
  SsoSilentRequest
} from "@azure/msal-browser";
import { MSAL_CONFIG } from "@/config/msal";
import { store } from "@/store/store";
import { AccountExtras, LoginRes } from "@/models/login"



export class AuthModule {
  public initialized: boolean;
  private myMSALObj: PublicClientApplication;
  public accountExtras: AccountExtras | null;
  public account: AccountInfo | null;
  private loginRedirectRequest: RedirectRequest;
  private loginRequest: PopupRequest;
  private profileRedirectRequest: RedirectRequest;
  private profileRequest: PopupRequest;
  private mailRedirectRequest: RedirectRequest;
  private mailRequest: PopupRequest;
  private silentProfileRequest: SilentRequest;
  private silentMailRequest: SilentRequest;
  private silentLoginRequest: SsoSilentRequest;

  constructor() {
    console.log("MSAL CONFIG: ", MSAL_CONFIG);
    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
    this.account = null;
    this.accountExtras = null;
    this.loginRequest = {
      scopes: [],
    };

    const redirectUrl = process.env.VUE_APP_MSAL_REDIRECT + "/login";

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: redirectUrl,
    };

    this.profileRequest = {
      scopes: ["User.Read"],
    };

    this.profileRedirectRequest = {
      ...this.profileRequest,
      redirectStartPage: redirectUrl,
    };

    // Add here scopes for access token to be used at MS Graph API endpoints.
    this.mailRequest = {
      scopes: ["Mail.Read"],
    };

    this.mailRedirectRequest = {
      ...this.mailRequest,
      redirectStartPage: window.location.href,
    };

    this.silentProfileRequest = {
      scopes: ["openid", "profile", "User.Read"],
      forceRefresh: false,
    };

    this.silentMailRequest = {
      scopes: ["openid", "profile", "Mail.Read"],
      forceRefresh: false,
    };

    this.silentLoginRequest = {
      scopes: [],
      loginHint: "michael.solomon@sharpsoftwaresolutions.net",
    };
    this.initialized = false;
  }

  private getAccount(): AccountInfo | null {
    const currentAccounts = this.myMSALObj.getAllAccounts();
    console.log("GET ACCOUNTS ", currentAccounts);
    if (currentAccounts === null) {
      console.log("No accounts detected");
      return null;
    }
    
    if (currentAccounts.length > 1) {
      // currentAccounts.find(el => el.username == )
      // Add choose account code here
      console.log(
        "Multiple accounts detected, need to add choose account code.", currentAccounts
      );
      // return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
    return null;
  }
  /**
   * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
   */
  // loadAuthModule(): void {
  //   this.myMSALObj
  //     .handleRedirectPromise()
  //     .then(async (resp: AuthenticationResult | null) => {
  //       await this.handleResponse(resp);
  //     })
  //     .catch(console.error);
  // }
  async loadAuthModule(): Promise<void> {
    try {
      console.log("Load Auth module");
      const resp = await this.myMSALObj.handleRedirectPromise();
      await this.handleResponse(resp);
      return Promise.resolve();
    } catch (err) {
      console.error("Auth initialization: ",err);
      return Promise.reject(err);
    }
  }

  async initializeAuthModule(): Promise<void> {
    try {
      const resp = await this.myMSALObj.handleRedirectPromise();
      await this.handleResponse(resp);
      // console.log("Return initialize");
      this.initialized = true;
      return Promise.resolve();
    } catch (err) {
      console.error("Auth initialization: ",err);
      return Promise.reject(err);
    }
    
  }

  /**
   * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
   * @param response
   */
  async handleResponse(response: AuthenticationResult | null): Promise<void> {
    try {
      console.log("Handling response ", response);
      if (response !== null) {
        //made a call and got a response
        this.account = response.account;
        this.accountExtras = {
          accessToken: response.accessToken,
          expiresOn: response.expiresOn,
          tokenType: response.tokenType
        }
      } else {
        //not in history
        this.account = this.getAccount();
        if(this.account) {
          const token = await this.getProfileTokenRedirect();
          if(token != null){
            this.accountExtras = {
              accessToken: token,
              expiresOn: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)),
              tokenType: "Bearer"
            }
          } else {
            console.log("No token in handle response")
          }
        }
        
        // console.log("Login request extras=", this.accountExtras);
      }

      if (this.account && this.accountExtras ) {
        // console.log("Account: ", this.account, this.accountExtras);
        // UIManager.showWelcomeMessage(this.account);
        store.commit("SET_LOGIN", { 
          account: this.account,
          accountExtras: this.accountExtras
        } as LoginRes);
        console.log("Returning from handling here");
        return Promise.resolve();
      }
    } catch (err) {
      console.error("HANDLE RESPONSE: ", err);
      return Promise.reject(err);
    }
    
  }
  /**
   * Calls ssoSilent to attempt silent flow. If it fails due to interaction required error, it will prompt the user to login using popup.
   * @param request
   */
  attemptSsoSilent() {
    console.log("Attemp SSO SIlent")
    this.myMSALObj
      .ssoSilent(this.silentLoginRequest)
      .then(() => {
        this.account = this.getAccount();
        if (this.account) {
          // UIManager.showWelcomeMessage(this.account);
        } else {
          console.log("No account!");
        }
      })
      .catch((error) => {
        console.error("Silent Error: " + error);
        if (error instanceof InteractionRequiredAuthError) {
          this.login("loginPopup");
        }
      });
  }
  /**
   * Calls loginPopup or loginRedirect based on given signInType.
   * @param signInType
   */
  async login(signInType: string): Promise<void> {
    try {
      // console.log("Login type ", signInType);
      if (signInType === "loginPopup") {
        console.log("login Popup");
        // this.myMSALObj
        //   // .loginPopup(this.loginRequest)
        //   .loginPopup()
        //   .then(async (resp: AuthenticationResult) => {
        //     await this.handleResponse(resp);
        //   })
        //   .catch(console.error);
      } else if (signInType === "loginRedirect") {
        console.log("login Redirect");
        await this.myMSALObj.loginRedirect(this.loginRedirectRequest).then(resp => {
          alert(resp);
        });
      }
      return Promise.resolve();
    } catch (err) {
      Promise.reject(err)
    }
    
  }
  /**
   * Logs out of current account.
   */
  logout(): void {
    // let account: AccountInfo | undefined;
    // if (this.account) {
    //   account = this.account;
    // }
    // const logOutRequest: EndSessionRequest = {
    //   account,
    // };
    
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${process.env.VUE_APP_MSAL_REDIRECT}`
    this.account = null,
    this.accountExtras = null;
    // this.myMSALObj.logoutRedirect(logOutRequest);
  }
  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive redirect.
   */
  async getProfileTokenRedirect(): Promise<string | null> {
    try {
      if (this.account) {
        this.silentProfileRequest.account = this.account;
      }
      return await this.getTokenRedirect(
        this.silentProfileRequest,
        this.profileRedirectRequest
      );
    } catch (err) {
      console.log("profile token ", err);
      return Promise.reject(err);
    }
    
  }
  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive popup.
   */
  async getProfileTokenPopup(): Promise<string | null> {
    if (this.account) {
      this.silentProfileRequest.account = this.account;
    }
    return this.getTokenPopup(this.silentProfileRequest, this.profileRequest);
  }
  async getMailTokenRedirect(): Promise<string | null> {
    if (this.account) {
      this.silentMailRequest.account = this.account;
    }
    return this.getTokenRedirect(
      this.silentMailRequest,
      this.mailRedirectRequest
    );
  }
  /**
   * Gets the token to read mail data from MS Graph silently, or falls back to interactive popup.
   */
  async getMailTokenPopup(): Promise<string | null> {
    if (this.account) {
      this.silentMailRequest.account = this.account;
    }
    return this.getTokenPopup(this.silentMailRequest, this.mailRequest);
  }

  /**
   * Gets a token silently, or falls back to interactive popup.
   */
  private async getTokenPopup(
    silentRequest: SilentRequest,
    interactiveRequest: PopupRequest
  ): Promise<string | null> {
    try {
      console.log("silent token acquisition");
      const response: AuthenticationResult = await this.myMSALObj.acquireTokenSilent(
        silentRequest
      );
      console.log("Popup response :", response);
      return response.accessToken;
    } catch (e) {
      console.log("silent token acquisition fails.");
      if (e instanceof InteractionRequiredAuthError) {
        console.log("acquiring token using redirect");
        return this.myMSALObj
          .acquireTokenPopup(interactiveRequest)
          .then((resp) => {
            return resp.accessToken;
          })
          .catch((err) => {
            console.error(err);
            return null;
          });
      } else {
        console.error(e);
      }
    }

    return null;
  }
  /**
   * Gets a token silently, or falls back to interactive redirect.
   */
  private async getTokenRedirect(
    silentRequest: SilentRequest,
    interactiveRequest: RedirectRequest
  ): Promise<string | null> {
    try {
      const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
      console.log("Redirect response :", response);
      return response.accessToken;
    } catch (e) {
      console.log("silent token acquisition fails.");
      if (e instanceof InteractionRequiredAuthError) {
        console.log("acquiring token using redirect");
        this.myMSALObj
          .acquireTokenRedirect(interactiveRequest)
          .catch(console.error);
      } else {
        console.error(e);
      }
    }

    return null;
  }
}

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          base: "#BFD131"
        },
        secondary: colors.grey.darken2,
        accent: "#BFD131",
        error: colors.red.darken3,
        info: colors.blue.base,
        success: colors.lightGreen.lighten1,
        warning: colors.orange.base,
        background: colors.grey.darken2,
        appBar: colors.grey.darken3,
        sideNav: colors.grey.darken1,
      },
      dark: {
          primary: "#BFD131",
          secondary: colors.grey.darken2,
          accent: "#BFD131",
          error: colors.red.darken3,
          warning: colors.orange.base,
          info: colors.blue.base,
          success: colors.lightGreen.lighten1,
          background: colors.grey.darken1,
          appBar: colors.grey.darken4,
          sideNav: colors.grey.darken1,
      },
    }
  }
});





















import Vue from "vue";
import { Component } from "vue-property-decorator";
import profileForm from "@/partials/newCollectionFormProfile.vue";
import dataServicesForm from "@/partials/newCollectionFormDataServices.vue";
import { MutationTypes } from "@/store/mutation-types";
import { goToPage } from "@/helpers/navigation";
import { ClientInfo, FormData } from "@/models/forms";
import DataServiceForm from "@/partials/newCollectionFormDataServices.vue";

@Component({
  components: {
    profileForm,
    dataServicesForm
  },
  methods: {
    // goToPage:
  }
})
export default class AddCollection extends Vue {
  public profileFormData: FormData | null = null;
  private goToPage!: (urlName: string, id?: string) => void;
  private dataServiceFormData: DataServiceForm | null = null;
  private banks = [];
  private currentComponent = {
    name: ""
  };
  private submittedClientInfo: ClientInfo | null = null;
  private loading = false;
  private inviteType = "";
  saveFormAndContinue({ formData, inviteType }: ClientInfo) {
    this.inviteType = inviteType;
    this.profileFormData = formData;
    this.submittedClientInfo = {
      formData,
      inviteType
    };
    this.currentComponent.name = "dataServicesForm";
  }
  async loadBanks(): Promise<void> {
    try {
      this.loading = true;
      const url = process.env.VUE_APP_BASE + "data-providers";
      const res = await this.$axiosInstance.get(url);
      this.banks = res.data;
      // console.log("Loaded Bank ", res.data, this.banks);
      Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  loadForm() {
    this.currentComponent.name = "profileForm";
  }
  async sendInvite(createdCollectionId: string): Promise<void> {
    try {
      this.loading = true;
      if (this.profileFormData == null) {
        throw "Profile Data is null";
      }
      let body = {};
      if (this.inviteType === "sms") {
        body = {
          recipient: this.profileFormData.mobile,
          sender: "Brand Data Collection",
          type: "sms",
          message:
            "Dear $name, $brand invites you to submit your digital bank statements using $url"
        };
      } else if (this.inviteType === "email") {
        body = {
          recipient: this.profileFormData.email,
          sender: "TruID Consultant Test",
          type: "email",
          subject: "Invite from truID",
          message:
            "Dear $name, $brand invites you to submit your digital bank statements using $url"
        };
      }
      // console.log("My body: ", body);
      const url =
        process.env.VUE_APP_BASE_COMMUNICATE +
        "collections/" +
        createdCollectionId +
        "/invites";
      await this.$axiosInstance.post(url, body);
      // console.log("Send INV: ", res);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  async submitProducts(selectedDataServices: DataServiceForm[]): Promise<void> {
    try {
      this.loading = true;
      const body = {
        ...this.profileFormData,
        services: selectedDataServices
      };
      const url = process.env.VUE_APP_BASE + "collections";
      const res = await this.$axiosInstance.post(url, body);
      if (res.status >= 200 && res.status <= 299) {
        let str = res.headers.location;
        // str = str.substring(str.indexOf(process.env.VUE_APP_URL));
        str = str.split(process.env.VUE_APP_URL)[1];
        // console.log("test me ", str, "vs ", process.env.VUE_APP_URL);
        this.$store.commit(MutationTypes.SET_NEWLY_CREATED_COLLECTION_ID, str);
        await this.sendInvite(str);
      } else {
        throw "Res status not OKAY status" + res;
      }
      goToPage("dash.landing");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    await this.loadBanks();
    this.currentComponent.name = "profileForm";
    this.$store.commit(MutationTypes.SET_TITLE, "Create Collection");
  }
}


const STATUS_CODES = {
    errors : [
        //red
        298, 299, 1298, 1301, 9000, 3000, 4000, 5000
    ],
    success: [       
        2000
    ]    
}

export { STATUS_CODES };



























import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class Login extends Vue {
  async login() {
    // console.log("Logging in", this.$authModule);
    await this.$authModule.login("loginRedirect");
  }
  mounted() {
    console.log("My env: ", process.env);
  }
}

import router from "@/router";

export const goToPage = (urlName: string, id?: string) => {
  if (id == undefined) {
    router.push({
      name: urlName,
    });
  } else {
    router.push({
      name: urlName,
      params: {
        id: id,
      },
    });
  }
};



















import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

@Component({})
export default class BackAndLogo extends Vue {
  // mounted() {
  //   console.log("Back button mounted ", this.$parent, this.$router.name);
  // }
}

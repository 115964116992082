export enum MutationTypes {
    SET_LOGIN = 'SET_LOGIN',
    SET_AUTHENTICATED = "SET_AUTHENTICATED",
    SET_TITLE = "SET_TITLE",
    SET_COMPANY_BRANDS = "SET_COMPANY_BRANDS",
    SET_CURRENT_BRAND = "SET_CURRENT_BRAND",
    SET_SELECTED_COLLECTION = "SET_SELECTED_COLLECTION",
    SET_SELECTED_COLLECTION_ID = "SET_SELECTED_COLLECTION_ID",
    SET_NEWLY_CREATED_COLLECTION_ID = "SET_NEWLY_CREATED_COLLECTION_ID",
    SET_COLLECTIONS = "SET_COLLECTIONS",
    SET_LOADING_COLLECTIONS = "SET_LOADING_COLLECTIONS",
    SET_ALL_COMPANIES = "SET_ALL_COMPANIES",
    SET_TABLE_OPTIONS = "SET_TABLE_OPTIONS",
    SET_TABLE_INFO = "SET_TABLE_INFO"
}
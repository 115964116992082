import { Collection } from '@/models/collections';
import { Brand, Company } from '@/models/company';
import { LoginRes } from '@/models/login'
import { MutationTypes } from './mutation-types';
import { State } from './state';
import { MutationTree } from 'vuex';
import router from '@/router';
import { DataOptions } from "vuetify/types";

export type Mutations<S = State> = {
  [MutationTypes.SET_LOGIN](state: S, payload: LoginRes | null): void;
  [MutationTypes.SET_AUTHENTICATED](state: S, payload: boolean): void;
  [MutationTypes.SET_TITLE](state: S, payload: string): void;
  [MutationTypes.SET_ALL_COMPANIES](state: S, payload: Company[]): void;
  [MutationTypes.SET_COMPANY_BRANDS](state: S, payload: Company | null): void;
  [MutationTypes.SET_CURRENT_BRAND](state: S, payload: Brand | null): void;
  [MutationTypes.SET_SELECTED_COLLECTION](state: S, payload: Collection | null): void;
  [MutationTypes.SET_SELECTED_COLLECTION_ID](state: S, payload: string | null): void;
  [MutationTypes.SET_NEWLY_CREATED_COLLECTION_ID](state: S, payload: string | null): void;
  [MutationTypes.SET_COLLECTIONS](state: S, payload: Collection[] | null): void;
  [MutationTypes.SET_LOADING_COLLECTIONS](state: S, payload: boolean): void;
  [MutationTypes.SET_TABLE_OPTIONS](state: S, payload: DataOptions): void;
  [MutationTypes.SET_TABLE_INFO](state: S, payload: {
    totalPages: number,
    totalElements: number
  }): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_ALL_COMPANIES](state, payload) {
    console.log("All COmpanies: ", payload );
    state.allCompanies = payload;
    if(payload.length == 0 ) {
      router.push({name: 'NoCompanies'})
    }
  },
  [MutationTypes.SET_TABLE_OPTIONS](state, payload) {
    console.log("Table Options: ", payload );
    state.tableOptions = payload;
  },
  [MutationTypes.SET_TABLE_INFO](state, payload) {
    console.log("Table Info: ", payload );
    state.tableInfo = payload;
  },
  [MutationTypes.SET_LOGIN](state, payload) {
      state.login = payload;
      if(payload != null) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
    },
    [MutationTypes.SET_AUTHENTICATED](state, payload) {
        state.isAuthenticated = payload
    },
    [MutationTypes.SET_TITLE](state, payload) {
        state.title = payload
    },
    [MutationTypes.SET_SELECTED_COLLECTION_ID](state, payload) {
        state.currentCollectionId = payload
    },
    [MutationTypes.SET_NEWLY_CREATED_COLLECTION_ID](state, payload) {
        state.newlyCreatedCollection = payload
    },
    [MutationTypes.SET_COMPANY_BRANDS](state, payload) {
      state.companyAndBrands = payload;
      // if (payload != null)
      //   state.currentBrand = payload.brands[0];
    },
    [MutationTypes.SET_CURRENT_BRAND](state, payload) {
      state.currentBrand = payload;
      if(payload != null) {
        state.allCompanies.forEach(company => {
          company.brands.forEach(brand => {
            if (brand.id == payload.id) {
              if(state.companyAndBrands != company) {
                state.companyAndBrands = company;
              }
              
            }
          })
        })
      }

    },
    [MutationTypes.SET_COLLECTIONS](state, payload) {
      state.collections = payload
    },
    [MutationTypes.SET_LOADING_COLLECTIONS](state, payload) {
      state.loadingCollections = payload
    },
    [MutationTypes.SET_SELECTED_COLLECTION](state, payload) {
      state.currentCollection = payload
    },
  }
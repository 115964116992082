import { goToPage } from '@/helpers/navigation';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "@/store/store";
import vuetify from "./plugins/vuetify";
import { AuthModule } from "@/helpers/AuthModule";
import axios from "@/plugins/axios";
import { NavigationGuardNext, Route } from "vue-router";
import BackAndLogo from "@/partials/backAndLogo.vue";

Vue.config.productionTip = false;
Vue.component("back-and-logo" , BackAndLogo)
const am: AuthModule = new AuthModule();
Vue.prototype.$authModule = am;
Vue.use(axios);
const vm = new Vue({
  router,
  store,
  vuetify,
  beforeMount() {
    // console.log("*****************before mount*******************");
  },
  mounted() {
    // console.log("*****************APPPP MOUNT*******************");
  },
  async beforeCreate() {
    // console.log("Before create");
    // await this.$authModule.initializeAuthModule();
  },
  render: h => h(App)
});

const authCheck = (to: Route, from: Route, next: NavigationGuardNext) => {
  // if(to.hash == ""){
  if (to.name != "Login" && store.getters["isLoggedIn"] == false) {
    next({
      name: "Login",
      // query: {
      //   redirectTo: to.name
      // }
      
    });
  } else if (to.name == "Login" && store.getters["isLoggedIn"] == true) {
    next({
      name: "Dashboard"
    });
  } else {
    next();
  }
  // }
  // else{
  //   next();
  // }
};

router.onReady(async () => {
  // console.log("*****************Router ready*******************");
  await vm.$authModule.initializeAuthModule();
  // console.log("*****************Mounting App*******************");
  if (vm.$authModule.account != null && vm.$authModule.accountExtras != null) {
    // console.log("Logged in so redirect");
    // console.log("Current", router.currentRoute);
    if (router.currentRoute.name?.includes("dash") == false) {
      goToPage("dash.landing")
    }
  }
  else {
    goToPage("Login");
  }
  vm.$mount("#app");
})

router.beforeEach(async (to, from, next) => {
  console.log("Router before each", to, from);
  if( from.name != "NoCompanies" && from.name != "NotFound"){
    authCheck(to, from, next);
  } 
});

// console.log("This is vm auth: ",vm.$authModule);

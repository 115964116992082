function asArray(value: string) {
  return value
    .toString()
    .split("")
    .map(Number);
}

function sum(array: number[], start: number, step: number, max: number) {
  let s = 0;
  let count = 0;
  for (let i = start; i < array.length && count < max; i += step) {
    s += array[i];
    count++;
  }
  return s;
}

function concat(array: number[], start: number, step: number, max: number) {
  let ct = "";
  let count = 0;
  for (let i = start; i < array.length && count < max; i += step) {
    ct = ct + array[i];
    count++;
  }

  return ct;
}

function toYear(year: string) {
  const temp = (parseInt(year) > 10 ? 1900 : 2000) + eval(year);
  return temp;
}
export const parseIdentity = (id: string) => {
  const identification = id.toString();

  if (
    identification == null ||
    (identification.length != 10 && identification.length != 13)
  ) {
    return null;
  }

  let valid = false;
  if (identification.length == 13) {
    const array = asArray(identification);
    const odd = sum(array, 0, 2, 6);
    const even = sum(
      asArray((parseInt(eval(concat(array, 1, 2, 6))) * 2).toString()),
      0,
      1,
      12
    );
    const list = asArray((odd + even).toString());
    let parity = 10 - list[list.length - 1];
    while (parity >= 10) {
      parity -= 10;
    }
    // Validity check parity
    valid = parity == array[array.length - 1];
  }

  const year = toYear(identification.substring(0, 2));
  const month = identification.substring(2, 4);
  const date = identification.substring(4, 6);

  return {
    birth:
      year +
      "-" +
      month.substring(month.length - 2, month.length) +
      "-" +
      date.substring(date.length - 2, date.length),
    gender:
      eval(identification.toString().substring(6, 10)) >= 5000 ? "M" : "F",
    valid: valid,
  };
};

import { Company } from "@/models/company";
import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import Vue from "vue";
import { DataOptions } from "vuetify/types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

export interface Actions {
  [ActionTypes.loadCompanyAndBrand](
    { commit, getters }: AugmentedActionContext,
    payload: {
      vm: Vue;
    }
  ): Promise<void>;
  [ActionTypes.loadCollections](
    { commit, getters }: AugmentedActionContext,
    payload: {
      vm: Vue;
    }
  ): Promise<void>;
}

export const actions: ActionTree<State, State> & Actions = {
  [ActionTypes.loadCompanyAndBrand](
    { commit, dispatch },
    { vm }
  ): Promise<void> {
    const url = process.env.VUE_APP_BASE + "companies";
    return vm.$axiosInstance
      .get(url)
      .then((res) => {
        console.log("Loading companies ");
        commit(MutationTypes.SET_ALL_COMPANIES, res.data);
        
      })
      .then(async () => {
        return await dispatch(ActionTypes.loadCollections, { vm });
        // return Promise.resolve();
      })
      .catch((e) => {
        commit(MutationTypes.SET_ALL_COMPANIES, []);
        // store.dispatch(ADD_TOAST_MESSAGE, { text : e.response.data, type : "danger", dismissAfter: 3000 });
        return new Promise((reject) => {
          reject(e);
        });
      });
  },
  async [ActionTypes.loadCollections]({ commit, getters }, { vm }) {
    try {
      commit(MutationTypes.SET_LOADING_COLLECTIONS, true);
      if (getters.getCurrentBrand == null) {
        throw "Select Brand";
      }
      const opts = getters.getTableOptions as DataOptions;
      

      const urlList =
        process.env.VUE_APP_BASE +
        "collections?brandId=" +
        getters.getCurrentBrand.id +
        `&page=${opts.page - 1}&size=${opts.itemsPerPage}`;
      const res = await vm.$axiosInstance.get(urlList);
      //filter and additional data is cut here only content here.
      commit(MutationTypes.SET_TABLE_INFO, {
        totalElements: res.data.totalElements,
        totalPages: res.data.totalPages
      })
      commit(MutationTypes.SET_COLLECTIONS, res.data.content);
      return Promise.resolve();
    } catch (err) {
      commit(MutationTypes.SET_COLLECTIONS, []);
      console.error("LOADING COLLECTIONS: ", err);
    } finally {
      commit(MutationTypes.SET_LOADING_COLLECTIONS, false);
    }
  },
};

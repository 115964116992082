import { Collection } from '@/models/collections';
import { Company, Brand } from '@/models/company';
import { LoginRes } from '@/models/login'
import { GetterTree } from 'vuex'
import { State } from './state'
import { DataOptions } from "vuetify/types";

export type Getters = {
  getLogin(state: State): LoginRes | null;
  getTitle(state: State): string;
  getCollections(state: State): Collection[] | null;
  getSelectedCollection(state: State): Collection | null;
  getSelectedCollectionId(state: State): string | null;
  getCreatedCollectionId(state: State): string | null;
  getCompany(state: State): Company | null;
  getAllCompanies(state: State): Company[];
  getCurrentBrand(state: State): Brand | null;
  getLoadingCollections(state: State): boolean;
  getTableOptions(state: State) : DataOptions,
  getTableInfo(state: State) : {      
      totalPages: number,
      totalElements: number
  },
}

export const getters: GetterTree<State, State> & Getters = {
    getTableOptions: (state) => {
        return state.tableOptions;
    },
    getTableInfo: (state) => {
        return state.tableInfo;
    },
    getLoadingCollections: (state) => {
        return state.loadingCollections;
    },
    getAllCompanies: (state) => {
        return state.allCompanies;
    },
    getSelectedCollection: (state) => {
        return state.currentCollection;
    },
    getCollections: (state) => {
        return state.collections;
    },
    getLogin: (state) => {
        return state.login;
    },
    isLoggedIn: (state) => {
        if(state.login && state.isAuthenticated){
            // console.error("Suck my auth");
            return true;
        } else if(!state.login){
            console.error("NO login");
            return false;
        } else if(!state.isAuthenticated){
            console.error("NO auth");
            return false;
        } else {
            return false
        }
        
    },
    getCompany: (state) => {
        return state.companyAndBrands;
    },
    getTitle: (state) => {
        return state.title;
    },
    getSelectedCollectionId: (state) => {
        return state.currentCollectionId;
    },
    getCreatedCollectionId: (state) => {
        return state.newlyCreatedCollection;
    },
    getCurrentBrand: (state) =>{
        return state.currentBrand;
    }
}
import { Collection } from '@/models/collections';
import { Company, Brand } from '@/models/company';
import { LoginRes } from "@/models/login"
import { DataOptions } from "vuetify/types";
export const state = {
    login: null,
    loginResponse: null,
    isAuthenticated: false,
    currentBrand: null,
    allCompanies: [],
    companyAndBrands: null,
    loadingCollections : false,
    currentCollectionId: null,
    currentCollection: null,
    collections: null,
    newlyCreatedCollection: null,
    title: 'Loading...',
    tableOptions: {
      page: 0,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    tableInfo: {
      totalPages: 0,
      totalElements: 0
    }
  }
  
  export type State = {
    login: LoginRes | null,
    isAuthenticated: boolean,
    title: string,
    allCompanies: Company[],
    collections: Collection[] | null, 
    currentCollection: Collection | null,
    currentCollectionId: string | null,
    newlyCreatedCollection: string | null,
    companyAndBrands: Company | null,
    currentBrand: Brand | null
    loadingCollections: boolean,
    tableOptions: DataOptions,
    tableInfo: {
      totalPages: number,
      totalElements: number
    }
  }







































































import { Company } from "@/models/company";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: mapGetters(["getCompany"])
})
export default class DataServiceForm extends Vue {
  @Prop({ required: true })
  brandId!: string;
  private getCompany!: Company;
  private selectedDataServices: DataServiceForm[] = [];
  private dataServices: DataServiceForm[] = [];
  private loadingDataServices = false;

  goBackToForm() {
    this.$emit("backToForm");
  }
  submit() {
    this.$emit("submitProducts", this.selectedDataServices);
  }
  async loadProducts(): Promise<void> {
    try {
      this.loadingDataServices = true;
      const url =
        process.env.VUE_APP_BASE +
        "companies/" +
        this.getCompany.id +
        "/brands/" +
        this.brandId +
        "/data-services";
      const res = await this.$axiosInstance.get(url);
      this.dataServices = res.data as DataServiceForm[];
      console.log("Loaded products ", res.data, this.dataServices);
      Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingDataServices = false;
    }
  }
  async mounted() {
    await this.loadProducts();
  }
}

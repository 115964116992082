import axios from 'axios';
import _Vue from "vue"
import { store } from "@/store/store"
import { LoginRes } from '@/models/login';

const axiosInstance = axios.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-API-KEY': process.env.VUE_APP_API_KEY,
    },
  });

// Encoding UTF8 ⇢ base64
function b64EncodeUnicode(str: string) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode(parseInt(p1, 16))
  }))
}
// Decoding base64 ⇢ UTF8

function decodeBase64(base64: string) {
  const text = atob(base64);
  const length = text.length;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
      bytes[i] = text.charCodeAt(i);
  }
  const decoder = new TextDecoder(); // default is utf-8
  return decoder.decode(bytes);
}


axiosInstance.interceptors.request.use(restConfig => {
  const newUser: LoginRes | null = store.getters.getLogin;
  if(newUser != null){
    // console.log("intercepting axios" , restConfig);
    
    const config = restConfig;
    // const loginRes = newUser.accountExtras.accessToken;
    config.headers.Authorization = newUser.accountExtras.tokenType + " " + newUser.accountExtras.accessToken;
    config.data = {
      // 'client_secret': process.env.VUE_APP_CLIENT_SECRET,
      'client_id': process.env.VUE_APP_CLIENT_ID,
      scope: process.env.VUE_APP_SCOPE,
      ...config.data
    }
    return config;
  }
  return restConfig
  
});

export default {
    install(Vue: typeof _Vue, options?: any) {
      Vue.prototype.$axiosInstance = axiosInstance;
    },
};
















































































































































































































































































































































































































// import jsPDF from "jspdf";
import "jspdf-autotable";
// import html2canvas from "html2canvas"
import { mapGetters } from "vuex";
import { goToPage } from "@/helpers/navigation";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Collection } from "@/models/collections";
import { Status } from "@/models/company";
import { MutationTypes } from "@/store/mutation-types";
import { LoginRes } from "@/models/login";
import { CustomDelivery, Delivery, dlTransactions } from "@/models/delivery";
import { AxiosResponse } from "node_modules/axios";
import { STATUS_CODES } from "@/config/errorCodes";

@Component({
  // methods: {
  //   goToPage
  // },
  computed: mapGetters([
    "getSelectedCollection",
    "getLogin",
    "getSelectedCollectionId"
  ])
})
export default class ViewCollection extends Vue {
  @Prop({ default: "none", required: true }) id!: string;
  public goToPage!: (urlName: string, id?: string) => void;
  private getSelectedCollectionId!: string;
  private getSelectedCollection!: Collection | null;
  private getLogin!: LoginRes | null;
  private headersPDF = ["Date", "Transaction", "Debit", "Credit", "Balance"];
  private selectedItemModal: CustomDelivery | null = null;
  private editedIndex = -1;
  private dialog = false;
  private viewTable = false;
  private display = true;
  private viewTableAccount = false;
  private delivery: Delivery[] = [];
  private statuses: Status[] = [];
  private editedItem: Status | null = null;
  private loadingTableData = false;
  private tableData: AxiosResponse | null = null;
  private loadingStatuses = false;
  private loadingDelivery = false;
  private loadingCollection = false;
  private headers = [
    {
      text: "Timestamp",
      align: "start",
      sortable: false,
      value: "timestamp"
    },
    { text: "Code", value: "code" },
    { text: "Message", value: "message" },
    { text: "", value: "actions", sortable: false }
  ];
  get currentStatusColor() {
    // return "red";
    if (this.statuses.length == 0) {
      return "grey";
    } else {
      if (STATUS_CODES.errors.includes(this.statuses[0].code)) {
        return "red";
      } else if (STATUS_CODES.success.includes(this.statuses[0].code)) {
        return "green";
      } else {
        return "grey";
      }
    }
  }
  get scope(): string[] {
    const arr: string[] = [];
    this.getSelectedCollection?.services.forEach(element => {
      if (arr.length > 0) {
        arr.push("\n" + element.name);
      } else {
        arr.push(element.name);
      }
    });
    return arr;
  }
  // get duration() {
  //   if (this.getSelectedCollection != null) {
  //     const length = this.getSelectedCollection.statuses.length;
  //     if (this.getSelectedCollection?.statuses[length - 1].code == 2000) {
  //       const oldTime = new Date(this.getSelectedCollection.statuses[0].timestamp);
  //       const latestTime = new Date(this.getSelectedCollection.statuses[length - 1].timestamp);
  //       const total = latestTime.getTime() - oldTime.getTime();
  //       const hours = Math.abs(Math.round((total / 1000) / (60 * 60)));
  //       const minutes = Math.abs(Math.round((total / 1000) / (60)));
  //       const seconds = Math.abs(Math.round((total / 1000)))
  //       return hours.toString() + "h " + minutes.toString() + "m " + seconds.toString() + "s";
  //     } else {
  //       return "Not Completed";
  //     }
  //   } else {
  //     return "Collection not ready yet";
  //   }
  // }
  get deliveryItems(): CustomDelivery[] {
    if (this.delivery.length == 0) {
      return [];
    }
    const LIST_NAMES = [
      "transaction_history_",
      "transaction_categorised_",
      "bank_statement_",
      "bank_statement_corrected_"
    ];
    const arr: CustomDelivery[] = [];
    for (let i = 0; i < this.delivery.length; i++) {
      let icon = "";
      let title = "";
      let iconClass = "";
      let currSubIndex = 0;
      if (
        LIST_NAMES.some((sub, index) => {
          currSubIndex = index;
          return this.delivery[i].name.includes(sub);
        })
      ) {
        const titArr = LIST_NAMES[currSubIndex].split("_");
        titArr.forEach((el, j) => {
          titArr[j] = el.charAt(0).toUpperCase() + el.slice(1);
        });
        let rest = this.delivery[i].name.split(LIST_NAMES[currSubIndex])[1];
        rest =
          "X" + Array.from(new Set(rest.toLowerCase().split("x"))).join("");
        console.log("Rest ", rest);
        title = titArr.join(" ") + rest.split(".")[0];
        icon = "mdi-file-lock";
        iconClass = "primary white--text";
      }
      // if (String(this.delivery[i].name).includes("bank_")) {
      //   icon = "mdi-file-lock";
      //   iconClass = "primary white--text";
      //   title = "Bank Statements";
      // } else if (String(this.delivery[i].name).includes("transaction_")) {
      //   icon = "mdi-file-table";
      //   iconClass = "primary white--text";
      //   title = "Transaction History";
      // } else if (String(this.delivery[i].name).includes("account_")) {
      //   icon = "mdi-account-lock";
      //   iconClass = "primary white--text";
      //   title = "Account Verification";
      // } else {
      //   icon = "mdi-file-lock";
      //   iconClass = "amber white--text";
      //   title = "Default";
      // }
      if (this.delivery[i].mimeType != "void") {
        arr[i] = {
          ...this.delivery[i],
          icon,
          iconClass,
          title
        } as CustomDelivery;
      }
    }
    return arr;
  }
  get headersTransactions() {
    if (this.tableData != null) {
      const headers = [];
      const keys = Object.keys(this.tableData.data.transactions[0]);
      // console.log(keys);
      for (const x in keys) {
        headers.push({
          text: String(keys[x]).toUpperCase(),
          align: "start",
          value: keys[x]
        });
      }
      return headers;
    } else {
      return [];
    }
  }
  get itemsTransactions() {
    if (this.tableData != null) {
      if (this.tableData.data.transactions) {
        return this.tableData.data.transactions;
      }
    }
    return [];
  }
  get indexedItems(): Status[] {
    return this.statuses.map((item, index) => ({
      id: index,
      ...item
    }));
  }
  go(str: string) {
    goToPage(str);
  }
  async loadAndOpenTable(item: CustomDelivery): Promise<void> {
    try {
      this.selectedItemModal = item;
      this.loadingTableData = true;
      const url =
        process.env.VUE_APP_BASE_DELIVERY +
        "collections/" +
        this.id +
        "/products/" +
        item.id;
      const res = await this.$axiosInstance.get(url);
      // console.log("Table res ", res);
      this.tableData = res;
      if (item.title.includes("Account")) {
        this.viewTableAccount = true;
      } else if (item.title.includes("Transaction")) {
        this.viewTable = true;
      }
      this.loadingTableData = false;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = null;
      this.editedIndex = -1;
    });
  }
  viewItem(item: Status) {
    this.editedIndex = this.indexedItems.indexOf(item);
    this.editedItem = item;
    this.dialog = true;
  }
  downloadPDF(item: Delivery) {
    try {
      this.loadingTableData = true;
      const url =
        process.env.VUE_APP_BASE_DELIVERY +
        "collections/" +
        this.id +
        "/products/" +
        item.id;
      this.$axiosInstance
        .get(url, {
          headers: {
            // 'Authorization': this.getCurrentTokenAndBearer.tokenType + " "+ this.getCurrentTokenAndBearer.accessToken,
            Accept: "application/json"
          },
          responseType: "blob"
        })
        .then(res => {
          console.log("PDF res ", res);
          this.loadingTableData = false;
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          let fileName = res.headers["content-disposition"];
          fileName = fileName.split("filename=")[1];
          fileName = fileName.substr(1, fileName.length - 2);
          console.log("Filename ", fileName);
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
          // that.$store.commit("SET_SELECTED_COLLECTION", res.data);
        })
        .catch((e) => {
          console.log("Error downloading collection:", e);
          if (e.response) {
            console.log("Error downloading collection response:", e.response);
          }
          this.loadingTableData = false;
        });
    } catch (err) {
      console.log("Trouble downloading ", err);
    }
  }
  async loadCurrentStatuses(): Promise<void> {
    try {
      this.loadingStatuses = true;
      const urlList =
        process.env.VUE_APP_BASE + "collections/" + this.id + "/statuses";
      const res = await this.$axiosInstance.get(urlList);
      this.statuses = res.data;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingStatuses = false;
    }
  }
  async loadCurrentCollection(): Promise<void> {
    try {
      this.loadingCollection = true;
      // console.log("Loading current collection");
      if (this.id != this.getSelectedCollectionId) {
        this.$store.commit(MutationTypes.SET_SELECTED_COLLECTION_ID, this.id);
      }
      const urlList = process.env.VUE_APP_BASE + "collections/" + this.id;
      const res = await this.$axiosInstance.get(urlList);
      this.$store.commit(MutationTypes.SET_SELECTED_COLLECTION, res.data);
      return Promise.resolve();
    } catch (err) {
      console.log("Error loading collection", err);
      return Promise.reject(err);
    } finally {
      this.loadingCollection = false;
    }
  }
  async markCollectionCompleted(): Promise<void> {
    try {
      const url = process.env.VUE_APP_BASE + "collections/" + this.id;
      const res = await this.$axiosInstance.post(url);
      console.log("Marked as completed ", res);
      goToPage("dash.landing");
      return Promise.resolve();
    } catch (err) {
      console.log("Error marking as completed ", err);
      return Promise.reject(err);
    }
  }
  async deleteCollection() {
    try {
      const url = process.env.VUE_APP_BASE + "collections/" + this.id;
      const res = await this.$axiosInstance.delete(url);
      console.log("Deleted collection ", res);
      goToPage("dash.landing");
      return Promise.resolve();
    } catch (err) {
      console.log("Error deleting: ", err);
      Promise.reject(err);
    }
  }
  async loadDeliveryAPI(): Promise<void> {
    try {
      this.loadingDelivery = true;
      const urlList =
        process.env.VUE_APP_BASE_DELIVERY +
        "collections/" +
        this.id +
        "/products";
      const res = await this.$axiosInstance.get(urlList);
      this.delivery = res.data as Delivery[];
    } catch (err) {
      Promise.reject(err);
    } finally {
      this.loadingDelivery = false;
    }
  }
  downloadJSON() {
    if (this.tableData != null) {
      const fileUrl = window.URL.createObjectURL(
        new Blob([this.tableData.data])
      );
      const fileLink = document.createElement("a");
      let fileName = this.tableData.headers["content-disposition"];
      fileName = fileName.split("filename=")[1];
      fileName = fileName.substr(1, fileName.length - 2);
      console.log("Filename ", fileName, this.tableData.headers);

      fileLink.href = fileUrl;
      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);

      fileLink.click();
    }
  }
  async refreshCollection() {
    await this.loadCurrentStatuses();
  }
  downloadCSV() {
    if (this.tableData != null) {
      const arrData = this.tableData.data.transactions as dlTransactions[];
      console.log("CSV ", this.tableData);
      for (const x in arrData) {
        arrData[x].description = String(arrData[x].description).replace(
          ";",
          " "
        );
      }
      console.log("arr data ", arrData);
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join("; "))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    }
  }
  // downloadWithCSS() {
  //   const doc = new jsPDF();

  //   doc.addImage(require("@/assets/truidlogo.png"), "PNG", 15, 20, 30,15);
  //   doc.addImage(require("@/assets/banks/fnb-logo.png"), "PNG", 165, 22, 30,10);
  //   doc.setLineWidth(1);
  //   doc.line(15, 35 , 195, 35, 'S');
  //   doc.line(15, 70 , 195, 70, 'S');
  //   doc.setFont('helvetica', 'normal');
  //   doc.setFontSize(9);
  //   doc.text(`Printed by ${this.getSelectedCollection.user.firstname} ${this.getSelectedCollection.user.lastname}`,110, 10, { align: "center"});
  //   doc.setFontSize(16);
  //   doc.text("STATEMENT",100, 30,{ align: "center"});
  //   doc.setFontSize(10);
  //   console.log("Fonts ", doc.getFontList());
  //   doc.setFont('helvetica', 'bold');
  //   doc.text("Generated on:",17, 45,{ align: "left"});
  //   doc.text("Submitted By:",17, 53,{ align: "left"});
  //   doc.text("Identification:",17, 61,{ align: "left"});
  //   doc.text("Account holder:",17, 80,{ align: "left"});
  //   doc.text("Account description:",17, 88,{ align: "left"});
  //   doc.text("Account number:",17, 96,{ align: "left"});
  //   doc.setFont('helvetica', 'normal');
  //   doc.text(`${new Date().toUTCString()}`,70, 45,{ align: "left"});
  //   doc.text(`${this.getSelectedCollection.name}`,70, 53,{ align: "left"});
  //   doc.text(`${this.getSelectedCollection.idNumber}`,70, 61,{ align: "left"});
  //   doc.text("Not sure where",70, 80,{ align: "left"});
  //   doc.text("Not sure where",70, 88,{ align: "left"});
  //   doc.text(`${this.tableData.data.account}`,70, 96,{ align: "left"});

  //   var tableHeightObj = {};
  //   doc.autoTable({
  //       theme: 'grid',
  //       body: this.itemsPDF,
  //       head: [this.headersPDF],
  //       styles:{},
  //       headStyles: { fillColor: 'gray' , textColor: '#fff',halign: 'center', cellPadding: {top: 5, right: 2, bottom: 6, left: 2} },
  //       bodyStyles: { fillColor: '#ffffff', textColor: '#000'},
  //       margin: { top: 105 },
  //       didParseCell: function (HookData) {
  //           tableHeightObj = HookData.table;
  //       }

  //   });

  //   doc.text("END OF STATEMENT",100, 10 + tableHeightObj.finalY,{ align: "center"});
  //   doc.text(`${this.getSelectedCollection.company.name}, registration number ${this.getSelectedCollection.company.registrationNr}`, 100, 284,{ align: "center"});
  //   doc.setTextColor("blue")
  //   doc.textWithLink('truID Website', 95, 290, { url: 'http://www.truid.com'});
  //   doc.save(this.selectedItemModal.name)
  //   // const doc = new jsPDF();
  //   // /** WITH CSS */
  //   // // var canvasElement = document.createElement('canvas');
  //   // var canvasElement = document.createElement('canvas');
  //   // html2canvas(this.$refs.content.$el, { canvas: canvasElement }).then(function (canvas) {
  //   //     const img = canvas.toDataURL("image/jpeg", 1);
  //   //     doc.text('This is the default font.',20, 10);
  //   //     doc.addImage(img,'JPEG',20,20, 100);
  //   //     doc.save("sample.pdf");
  //   // });
  // },
  // covertTableToPdf(){
  //     const doc = new jsPDF();
  //     /** WITHOUT CSS */
  //     const contentHtml = this.$refs.content.$el;
  //     doc.text('This is the default font.',20, 20);
  //     doc.html(contentHtml, {
  //         callback: function(){
  //             window.open(doc.output('bloburl'));
  //             window.resizeTo(10, 50);
  //         }
  //     });
  //     doc.text('Text after table.',20, 50);
  //     doc.save("sampleWz.pdf");
  // }
  mounted() {
    this.loadCurrentCollection();
    this.loadCurrentStatuses();
    this.loadDeliveryAPI();
    this.$store.commit(
      MutationTypes.SET_TITLE,
      `Collection ${this.getSelectedCollectionId}`
    );
  }
}

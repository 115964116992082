






























































































































































































































import Vue from "vue";
import { goToPage } from "@/helpers/navigation";
import { Collection } from "@/models/collections";
import { mapGetters } from "vuex";
import { ActionTypes } from "@/store/action-types";
import { Component, Watch } from "vue-property-decorator";
import { STATUS_CODES } from "@/config/errorCodes";
import { DataOptions } from "vuetify/types";
import { MutationTypes } from "@/store/mutation-types";

@Component({
  methods: {
    // goToPage
  },
  computed: mapGetters([
    "getCollections",
    "getLoadingCollections",
    "getTableOptions",
    "getTableInfo"
  ])
})
export default class Landing extends Vue {
  private getCollections!: Collection[];
  private getLoadingCollections!: boolean;
  // goToPage!: (urlName: string, id?: string) => void;
  private getTableOptions!: DataOptions;
  private getTableInfo!: {
    totalElements: number;
    totalPages: number;
  };
  private tableOpts = {};
  private page = 0;
  private numberOfPages = 0;
  private totalItems = 0;
  private filterOpen = false;
  private singleExpand = false;
  private headers = [
    { text: "Created", value: "created", align: "start", width: 120 },
    { text: "Status Code", value: "statusCode", width: 100, align: "start" },
    { text: "Last Status", value: "status", width: 100, align: "start" },
    {
      text: "ID",
      value: "id",
      align: "start",
      sortable: false
    },
    { text: "Applicant", value: "name" },
    { text: "ID Number", value: "idNumber" },
    { text: "Company", value: "companyName", width: 150 },
    { text: "Branch", value: "brandName", width: 100 },
    { text: "Created By", value: "createdBy", align: "start", width: 120 },
    { text: "Consultant", value: "userName", align: "start", width: 120 }
  ];
  loadColl() {
    console.log("Refresh Coll List");
    this.$store.dispatch(ActionTypes.loadCollections, { vm: this });
  }
  checkLastStatusColor(lastStatus: number) {
    if (STATUS_CODES.errors.includes(lastStatus)) {
      return "red";
    } else if (STATUS_CODES.success.includes(lastStatus)) {
      return "green";
    } else {
      return "grey";
    }
  }
  handleRowSelection(value: Collection) {
    // console.log("Choosing- ", value);
    // var text = "Opening Collection: " + value.name;
    // this.$store.dispatch(ADD_TOAST_MESSAGE, { text : text, type : "success", dismissAfter: 3000 });
    this.$store.commit("SET_SELECTED_COLLECTION_ID", value.id);
    goToPage("dash.collection.view", value.id);
  }
  get items() {
    if (this.getCollections != null) {
      return this.getCollections;
    } else {
      return [];
    }
  }
  @Watch("tableOpts", { immediate: true, deep: true })
  changedOptions(opt: DataOptions) {
    if (opt != undefined) {
      // if (opt != getters.getTableOptions) {

      // }
      this.$store.commit(MutationTypes.SET_TABLE_OPTIONS, opt);
      this.$store.dispatch(ActionTypes.loadCollections, {
        vm: this
      });
    }
  }
  // @Watch("getCompany")
  // onCompanyChanged() {
  //   console.log("Changing company");
  //   this.loadColl();
  //   // if (this.getCompany.brands.length > 0) {
  //   //   this.brand = this.getCompany.brands[0];
  //   // }
  // }
  mounted() {
    this.$store.commit("SET_TITLE", "Dashboard");
    this.loadColl();
  }
}

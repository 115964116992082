import { Configuration, LogLevel } from "@azure/msal-browser";
const MSAL_CONFIG: Configuration = {
    auth: {
        clientId: process.env.VUE_APP_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.VUE_APP_TENTANT_ID}`,
        redirectUri: process.env.VUE_APP_MSAL_REDIRECT,
        postLogoutRedirectUri: process.env.VUE_APP_MSAL_REDIRECT
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {	
                    return;	
                }	
                switch (level) {	
                    case LogLevel.Error:	
                        console.error(message);	
                        return;	
                    case LogLevel.Info:	
                        console.info(message);	
                        return;	
                    case LogLevel.Verbose:	
                        console.debug(message);	
                        return;	
                    case LogLevel.Warning:	
                        console.warn(message);	
                        return;	
                }
            }
        }
    }
};

export {
    MSAL_CONFIG
}